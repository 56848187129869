// @ts-nocheck

import React, { useEffect, useState, useRef } from "react";

import {
  Title,
  Form,
  FormItem,
  Text,
  Table,
  TableColumn,
  TableCell,
  Label,
  TableRow,
  FlexBox,
  Button,
  ObjectPage,
  DynamicPageHeader,
  DynamicPageTitle,
  ObjectPageSection,
  Breadcrumbs,
  Link,
  BreadcrumbsItem,
  ObjectStatus
} from "@ui5/webcomponents-react";
import { useI18nBundle } from "@ui5/webcomponents-react-base";
import { PieChart } from "@ui5/webcomponents-react-charts";
import { useNavigate, useLocation } from "react-router-dom";
import CreateOffer from "./CreateOffer";
import UploadOffers from "./UploadOffers";
import Amplify, { API } from "aws-amplify";
import { listOffers } from "../graphql/queries";
import awsExports from "../aws-exports";
Amplify.configure(awsExports);

function OpenBadgeDetails() {
  let navigate = useNavigate();
  const i18nBundle = useI18nBundle("credentialsApp");

  const { state } = useLocation();
  const { openBadge } = state;

  const createOfferInitialState = {
    firstName: "",
    lastName: "",
    email: "",
    orgName: "",
    role: "USER",
    credentialId: openBadge.id,
    credentialType: "OPENBADGE",
    isOrgCredential: openBadge.isOrgCredential,
  };
  const [createOfferState, setCreateOfferState] = useState(
    createOfferInitialState
  );

  const uploadOffersInitialState = {
    role: "USER",
    credentialId: openBadge.id,
    credentialType: "OPENBADGE",
    isOrgCredential: openBadge.isOrgCredential,
  };
  const [uploadOffersState, setUploadOffersState] = useState(
    uploadOffersInitialState
  );

  const [offerDialogState, setOfferDialogState] = useState();
  const [csvOffersDialogState, setCSVOffersDialogState] = useState();

  const [offers, setOffers] = useState([]);
  const [nextToken, setNextToken] = useState();
  const [offerRows, setOfferRows] = useState([]);
  const [showGrow, setShowGrow] = useState("None");

  let urlPrefix = "";
  const host = window.location.host;
  if (host.startsWith("localhost") || host.indexOf("decreds") > -1) {
    urlPrefix = "https://cdn.decreds.io/public/"; //local Dev + Testing
  } else {
    urlPrefix = "https://cdn.sap-credentials.io/public/"; //Prod
  }

  const listOnlyOffers = /* GraphQL */ `
  query ListOffers(
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        offerOpenBadgeId
        offerCertificationId
      }
      nextToken
    }
  }
  `;

  useEffect(() => {
    setShowGrow("None");
    setNextToken(null);
    fetchOnlyOffers();
    fetchOffersData(function(offersData){
      let offers = offersData.data.listOffers.items;
      let newNextToken = offersData.data.listOffers.nextToken;
      setNextToken(newNextToken);
      if(newNextToken){
        setShowGrow("Button");
      }else{
        setShowGrow("None");
      }
      setOfferRows(createOfferRows(offers));
    });
  }, []);

  async function fetchOffersData(fnCallback) {
    try {
      let filter = {
        offerOpenBadgeId: {
          eq: openBadge.id,
        },
      };
      let variables = { filter: filter, limit: 10000};
      if(nextToken){
        variables.nextToken = nextToken;
      }

      const offersData = await API.graphql({
        query: listOffers,
        variables: variables,
      });

      fnCallback(offersData);
    } catch (err) {
      console.log("error fetching openBadges" + err);
    }
  }

  async function fetchOnlyOffers() {
    try {
      let filter = {
        offerOpenBadgeId: {
          eq: openBadge.id,
        },
      };
      let variables = { filter: filter, limit : 10000 };

      const onlyOffers = await API.graphql({
        query: listOnlyOffers,
        variables: variables,
      });

      setOffers(onlyOffers.data.listOffers.items);

    } catch (err) {
      console.log("error fetching openBadges" + err);
    }
  }

  function onOfferAddClick() {
    setCreateOfferState(createOfferInitialState);
    setOfferDialogState(true);
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function getTotalCount(offers) {
    return offers.length;
  }

  function getPendingCount(offers) {
    let total = offers.length;
    let accepted = getAcceptedCount(offers);
    let revoked = getRevokedCount(offers);
    return total - accepted - revoked;
  }

  function getAcceptedCount(offers) {
    let count = 0;
    offers.forEach((offer) => {
      if (offer.status === "ACCEPTED") {
        count++;
      }
    });
    return count;
  }

  function getRevokedCount(offers) {
    let count = 0;
    offers.forEach((offer) => {
      if (offer.status === "REVOKED") {
        count++;
      }
    });
    return count;
  }

  function onBackClick() {
    navigate("/home", { state: { view: "manageopenbadges" } });
  }

  function onUploadCSVClick() {
    setUploadOffersState(uploadOffersInitialState);
    setCSVOffersDialogState(true);
  }

  function isCriteriaURL() {
    return openBadge.criteria.startsWith("http");
  }

  const createOfferRows = (offers) => {
    return offers.map((offer) => (
      <TableRow>
        <TableCell>
          <Label>{offer.user.firstName}</Label>
        </TableCell>
        <TableCell>
          <Label>{offer.user.lastName}</Label>
        </TableCell>
        <TableCell>
          <Label>{offer.user.email}</Label>
        </TableCell>
        {openBadge.isOrgCredential && (
          <TableCell>
            <Label>{offer.user.orgName}</Label>
          </TableCell>
        )}
        <TableCell>
          <Label>{offer.status}</Label>
        </TableCell>
        <TableCell>
          <Label>{formatDate(offer.createdAt)}</Label>
        </TableCell>
        <TableCell>
          <Label>{formatDate(offer.updatedAt)}</Label>
        </TableCell>
      </TableRow>
    ));
  };

  async function onLoadMoreOffers() {
    if(nextToken){
      fetchOffersData(function(offersData){
        let offers = offersData.data.listOffers.items;
        let newNextToken = offersData.data.listOffers.nextToken;
        setNextToken(newNextToken);
        if(newNextToken){
          setShowGrow("Button");
        }else{
          setShowGrow("None");
        }
        setOfferRows((prev) => [...prev, ...createOfferRows(offers)]);
      });
    }
  }

  return (
    <>
      <ObjectPage
        headerContent={
          <DynamicPageHeader>
            <FlexBox direction="Row" justifyContent="SpaceBetween">
              <FlexBox direction="Column" style={{ width: "50%" }}>
                <Text style={{ padding: "10px" }}>OpenBadge V2.0</Text>
                <Text style={{ padding: "10px" }}>{openBadge.description}</Text>
                <Form style={{ padding: "10px" }}>
                  <FormItem label={i18nBundle.getText("Criteria")}>
                    {isCriteriaURL() && (
                      <Link wrappingType="Normal">{openBadge.criteria}</Link>
                    )}
                    {!isCriteriaURL() && <Text>{openBadge.criteria}</Text>}
                  </FormItem>
                  <FormItem label={i18nBundle.getText("Issuer")}>
                    <Title level="H5">SAP</Title>
                  </FormItem>
                  <FormItem label={i18nBundle.getText("ID")}>
                    <Text>{openBadge.id}</Text>
                  </FormItem>
                  <FormItem
                    label={i18nBundle.getText("Is Organization Credential")}
                  >
                    <Label wrappingType="Normal">
                      {openBadge.isOrgCredential.toString()}
                    </Label>
                  </FormItem>
                  <FormItem label={i18nBundle.getText("Is Shareable")}>
                    <Label wrappingType="Normal">
                      {openBadge.isShareable.toString()}
                    </Label>
                  </FormItem>
                  <FormItem label={i18nBundle.getText("Created By")}>
                    <Label wrappingType="Normal">{openBadge.createdBy}</Label>
                  </FormItem>
                </Form>
              </FlexBox>
              <FlexBox
                direction="Column"
                justifyContent="SpaceAround"
                style={{
                  width: "50%",
                }}
              >
                <PieChart
                  dataset={[
                    {
                      name: "Offers Pending",
                      count: getPendingCount(offers),
                    },
                    {
                      name: "Revoked",
                      count: getRevokedCount(offers),
                    },
                    {
                      name: "Accepted",
                      count: getAcceptedCount(offers),
                    },
                  ]}
                  dimension={{
                    accessor: "name",
                  }}
                  measure={{
                    accessor: "count",
                  }}
                  style={{
                    width: "100%",
                  }}
                />
                <Title
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Total Offers : {getTotalCount(offers)}
                </Title>
              </FlexBox>
            </FlexBox>
          </DynamicPageHeader>
        }
        headerTitle={
          <DynamicPageTitle
            breadcrumbs={
              <Breadcrumbs onItemClick={onBackClick}>
                <BreadcrumbsItem>Home</BreadcrumbsItem>
                <BreadcrumbsItem>Credential Details</BreadcrumbsItem>
              </Breadcrumbs>
            }
            header={openBadge.name}
            showSubHeaderRight="false"
            subHeader
          >
            <ObjectStatus state="Success">Active</ObjectStatus>
          </DynamicPageTitle>
        }
        image={urlPrefix + openBadge.imageUrl}
        showHideHeaderButton
        style={{
          height: "100%",
        }}
      >
        <ObjectPageSection titleText="User Offers">
          <FlexBox
            style={{
              width: "100%",
            }}
            direction="Row"
            justifyContent="End"
          >
            <Button
              icon="upload"
              onClick={onUploadCSVClick}
              style={{
                margin: "8px",
              }}
            >
              Upload CSV
            </Button>
            <Button
              icon="add"
              onClick={onOfferAddClick}
              style={{
                margin: "8px",
              }}
            >
              Add
            </Button>
          </FlexBox>

          <CreateOffer
            createOfferState={createOfferState}
            setCreateOfferState={setCreateOfferState}
            offerDialogState={offerDialogState}
            setOfferDialogState={setOfferDialogState}
            offers={offers}
            setOffers={setOffers}
            createOfferRows={createOfferRows}
            offerRows={offerRows}
            setOfferRows={setOfferRows}
          />

          <UploadOffers
            uploadOffersState={uploadOffersState}
            setUploadOffersState={setUploadOffersState}
            csvOffersDialogState={csvOffersDialogState}
            setCSVOffersDialogState={setCSVOffersDialogState}
            offers={offers}
            setOffers={setOffers}
            createOfferRows={createOfferRows}
            offerRows={offerRows}
            setOfferRows={setOfferRows}
          />

          <Table
            growing={showGrow}
            onLoadMore={onLoadMoreOffers}
            columns={
              <>
                <TableColumn>
                  <Label>First Name</Label>
                </TableColumn>
                <TableColumn>
                  <Label>Last Name</Label>
                </TableColumn>
                <TableColumn>
                  <Label>Email</Label>
                </TableColumn>
                {openBadge.isOrgCredential && (
                  <TableColumn>
                    <Label>Organization</Label>
                  </TableColumn>
                )}
                <TableColumn>
                  <Label>Status</Label>
                </TableColumn>
                <TableColumn>
                  <Label>Created</Label>
                </TableColumn>
                <TableColumn>
                  <Label>Updated</Label>
                </TableColumn>
              </>
            }
          >
            {offerRows}
          </Table>

        </ObjectPageSection>
      </ObjectPage>
    </>
  );
}

export default OpenBadgeDetails;
