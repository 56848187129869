// @ts-nocheck

import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  ShellBar,
  Avatar,
  Title,
  Text,
  AvatarShape,
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/AllIcons.js";
import ProfilePopover from "./UserProfile";

import { useI18nBundle } from "@ui5/webcomponents-react-base";

import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

function Main() {
  let navigate = useNavigate();
  const i18nBundle = useI18nBundle("credentialsApp");

  const [userEmail, setUserEmail] = useState();
  const [popoverProfileState, setPopoverProfileState] = useState(false);

  useEffect(() => {
    getCurrentUser();
  }, []);

  async function getCurrentUser() {
    try {
      const loggedInUser = await Auth.currentAuthenticatedUser();
      if (loggedInUser.attributes.email_verified) {
        let userEmail = loggedInUser.attributes.email;
        setUserEmail(userEmail);
        navigate("/home", { state: { userEmail: userEmail } });
      }
      console.log(loggedInUser);
    } catch (err) {
      console.log(err.message);
    }
  }

  return (
    <>
      <ShellBar
        profile={
          <Avatar
            id="avatar"
            icon="employee"
            shape={AvatarShape.Circle}
            size="XS"
          />
        }
        onProfileClick={() => setPopoverProfileState(true)}
        primaryTitle="SAP Verifiable Credentials - powered by Velocity Network Foundation"
        showNotifications={false}
        showProductSwitch={false}
        logo={<img src="logo.png" alt="SAP Verifiable Credentials" />}
        onLogoClick={() => {
          navigate("/home");
        }}
      />

      <ProfilePopover
        popoverProfileState={popoverProfileState}
        setPopoverProfileState={setPopoverProfileState}
      />

      <FlexBox
        justifyContent="SpaceBetween"
        direction="Row"
        style={{ margin: "16px", width: "96%" }}
      >
        <FlexBox direction="Column">
          <Title>Welcome Bharath Medarametla!</Title>
          <Title level="H4">
            Issue, Verify and Manage Verifiable Credentials powered by Velocity
            Network Foundation
          </Title>
        </FlexBox>
        <FlexBox direction="Column">
          <Title>Admin</Title>
          <Text>{userEmail}</Text>
        </FlexBox>
      </FlexBox>
      <FlexBox
        direction={FlexBoxDirection.Column}
        alignItems={FlexBoxAlignItems.Center}
      >
        <Outlet />
      </FlexBox>
    </>
  );
}

export default Main;
