// @ts-nocheck

import { useState, useRef } from "react";
import {
  Form,
  FormItem,
  TextArea,
  Bar,
  Button,
  Toast,
  Page,
  Title,
  DatePicker,
  Switch,
} from "@ui5/webcomponents-react";
import { useI18nBundle } from "@ui5/webcomponents-react-base";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";

import Amplify, { API, graphqlOperation } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import { createCertification } from "../graphql/mutations";
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

function CreateCertification() {
  const i18nBundle = useI18nBundle("myApp");
  const toast = useRef();

  let navigate = useNavigate();
  const { state } = useLocation();
  const { userEmail } = state;

  const certificationInitialState = {
    type: "CERTIFICATION",
    version: "1.1",
    status: "ACTIVE",
    isOrgCredential: false,
    isShareable: false,
    createdBy: "bharath.medarametla@sap.com",
    name: "",
    description: "",
    validFrom: "",
    validUntil: "",
  };
  const [certificationState, setCertificationState] = useState(
    certificationInitialState
  );

  async function handleInputChanges(key, value) {
    await setCertificationState({
      ...certificationState,
      [key]: value,
    });
  }

  const onCreateClick = () => {
    if (
      !certificationState.name ||
      !certificationState.description ||
      !certificationState.validFrom ||
      !certificationState.validUntil
    ) {
      toast.current.show();
    } else {
      addCertification();
    }
  };

  function onHomeClick() {
    navigate("/home");
  }

  function onCancelClick() {
    navigate("/home", { state: { view: "managecertifications" } });
  }

  async function addCertification() {
    try {
      const certification = { ...certificationState }

      const apiCall = await API.graphql(
        graphqlOperation(createCertification, { input: certification })
      );
      
      const newCertification = apiCall.data.createCertification;
      navigate("/certificationDetails", { state: { certification: newCertification } });

    } catch (err) {
      console.log("error creating Transaction:", err);
    }
  }

  const formCreateCertification = (
    <>
      <Form style={{ margin: "32px" }}>
        <FormItem label={i18nBundle.getText("Name")}>
          <TextArea
            required="true"
            rows="2"
            growing="true"
            style={{ width: "450px" }}
            value={certificationState.name}
            onInput={(event) => handleInputChanges("name", event.target.value)}
          />
        </FormItem>
        <FormItem label={i18nBundle.getText("Description")}>
          <TextArea
            required="true"
            rows="3"
            growing="true"
            style={{ width: "450px" }}
            value={certificationState.description}
            onInput={(event) =>
              handleInputChanges("description", event.target.value)
            }
          />
        </FormItem>
        <FormItem label={i18nBundle.getText("Issuer")}>
          <Title level="H5">SAP</Title>
        </FormItem>
        <FormItem label={i18nBundle.getText("Valid From")}>
          <DatePicker
            onChange={(event) =>
              handleInputChanges("validFrom", event.detail.value)
            }
            value={certificationState.validFrom}
            primaryCalendarType="Gregorian"
          />
        </FormItem>
        <FormItem label={i18nBundle.getText("Valid Until")}>
          <DatePicker
            onChange={(event) =>
              handleInputChanges("validUntil", event.detail.value)
            }
            value={certificationState.validUntil}
            primaryCalendarType="Gregorian"
          />
        </FormItem>
        <FormItem label={i18nBundle.getText("Is Organization Credential")}>
          <Switch
            design="Graphical"
            onChange={function noRefCheck(event) {
              certificationState.isOrgCredential = event.returnValue;
            }}
          />
        </FormItem>
        <FormItem label={i18nBundle.getText("Is Sharable")}>
          <Switch
            design="Graphical"
            onChange={function noRefCheck(event) {
              certificationState.isShareable = event.returnValue;
            }}
          />
        </FormItem>
      </Form>
      <Toast ref={toast}>
        {i18nBundle.getText("Please fill in all fields")}
      </Toast>
    </>
  );

  return (
    <>
    <Page
      footer={
        <Bar
          endContent={
            <>
              <Button icon="sys-cancel" onClick={onCancelClick}>
                {i18nBundle.getText("Cancel")}
              </Button>
              <Button icon="create" onClick={onCreateClick}>
                {i18nBundle.getText("Create")}
              </Button>
            </>
          }
        />
      }
      header={
        <Bar startContent={<Button icon="home" onClick={onHomeClick} />}>
          <Title>{i18nBundle.getText("Create Certification")}</Title>
        </Bar>
      }
      style={{
        height: "600px",
      }}
    >
      {formCreateCertification}
    </Page>
    </>
  );
}

export default CreateCertification;
