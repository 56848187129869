// @ts-nocheck

import React, { useEffect, useState } from "react";
import {
  List,
  CustomListItem,
  FlexBox,
  Title,
  Text,
  FlexBoxAlignItems ,
} from "@ui5/webcomponents-react";
import { Image } from "@aws-amplify/ui-react";
import "@ui5/webcomponents-icons/dist/AllIcons.js";

import { useI18nBundle } from "@ui5/webcomponents-react-base";

import Amplify, { API, Auth, graphqlOperation } from "aws-amplify";
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

function Wallets() {
  useEffect(() => {}, []);

  return (
    <>
          <List headerText="Velocity Network Foundation Wallets">
            <CustomListItem
              style={{
                margin: "8px",
              }}
            >
              <FlexBox
                direction="Row"
                alignItems={FlexBoxAlignItems.Center}
                justifyContent="SpaceBetween"
                style={{
                  width: "100%",
                }}
              >
                <FlexBox
                  direction="Column"
                  alignItems={FlexBoxAlignItems.Center}
                >
                  <Title level="H5">Velocity Career Wallet</Title>
                  <Image
                    src={
                      "https://www.velocitynetwork.foundation/wp-content/uploads/2022/06/logo-150X150-150x150.png"
                    }
                    height="12rem"
                    width="12rem"
                  />
                </FlexBox>
                <Text
                  style={{
                    width: "320px",
                    margin: "16px",
                  }}
                >
                  Claim digitally-signed education and career credentials from
                  your work, school, license issuers, and certification
                  providers.​ Store them privately and control who you share
                  them with.
                </Text>
                <Image
                  src={
                    "https://developer.apple.com/news/images/download-on-the-app-store-badge.png"
                  }
                  height="3rem"
                  width="8rem"
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/velocity-career-wallet/id1587589679",
                      "_blank"
                    )
                  }
                />

                <Image
                  src={
                    "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  }
                  height="4rem"
                  width="8rem"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=io.velocitycareerlabs.holderapp",
                      "_blank"
                    )
                  }
                />
              </FlexBox>
            </CustomListItem>
{/*             <CustomListItem
              style={{
                margin: "8px",
              }}
            >
              <FlexBox
                direction="Row"
                alignItems={FlexBoxAlignItems.Center}
                justifyContent="SpaceBetween"
                style={{
                  width: "100%",
                }}
              >
                <FlexBox
                  direction="Column"
                  alignItems={FlexBoxAlignItems.Center}
                >
                  <Title level="H5">Wallet Kit</Title>
                  <Image
                    src={
                      "https://www.velocitynetwork.foundation/wp-content/uploads/2022/06/Walt.id_Logo_transparent-150x150.png"
                    }
                    height="12rem"
                    width="12rem"
                  />
                </FlexBox>
                <Text
                  style={{
                    width: "320px",
                    margin: "16px",
                  }}
                >
                  Everything you need to launch your own identity wallet or
                  extend your existing app with holistic identity capabilities.
                </Text>
                <Image
                  src={
                    "https://developer.apple.com/news/images/download-on-the-app-store-badge.png"
                  }
                  height="3rem"
                  width="8rem"
                />

                <Image
                  src={
                    "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  }
                  height="4rem"
                  width="8rem"
                />
              </FlexBox>
            </CustomListItem>
            <CustomListItem
              style={{
                margin: "8px",
              }}
            >
              <FlexBox
                direction="Row"
                alignItems={FlexBoxAlignItems.Center}
                justifyContent="SpaceBetween"
                style={{
                  width: "100%",
                }}
              >
                <FlexBox
                  direction="Column"
                  alignItems={FlexBoxAlignItems.Center}
                >
                  <Title level="H5">Yoti Digital ID</Title>
                  <Image
                    src={
                      "https://www.velocitynetwork.foundation/wp-content/uploads/2022/06/yoti_logo_email_signature_box-150x150.png"
                    }
                    height="12rem"
                    width="12rem"
                  />
                </FlexBox>
                <Text
                  style={{
                    width: "320px",
                    margin: "16px",
                  }}
                >
                  An easy way for customers to prove who they are, online and in
                  person. Store credentials in the secure wallet and share in
                  seconds.
                </Text>
                <Image
                  src={
                    "https://developer.apple.com/news/images/download-on-the-app-store-badge.png"
                  }
                  height="3rem"
                  width="8rem"
                />

                <Image
                  src={
                    "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  }
                  height="4rem"
                  width="8rem"
                />
              </FlexBox>
            </CustomListItem> */}
          </List>
    </>
  );
}

export default Wallets;
