// @ts-nocheck
import {
  Form,
  FormItem,
  FlexBox,
  TextArea,
  Bar,
  Button,
  Toast,
  Switch,
  Title,
  SelectDialog,
  CustomListItem,
  Page,
  Label,
} from "@ui5/webcomponents-react";
import { useI18nBundle } from "@ui5/webcomponents-react-base";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";

import Amplify, { API, graphqlOperation, Storage } from "aws-amplify";
import { Image } from "@aws-amplify/ui-react";
import { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createOpenBadge } from "../graphql/mutations";
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

function CreateOpenBadge() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { userEmail, cdnUrlPrefix } = state;


  const i18nBundle = useI18nBundle("myApp");
  const toast = useRef();

  const openBadgeInitialState = {
    type: "OPENBADGE",
    version: "2.0",
    status: "ACTIVE",
    isOrgCredential: false,
    isShareable: false,
    createdBy: "bharath.medarametla@sap.com",
    name: "",
    description: "",
    imageUrl: "",
    criteria: "",
  };
  const [openBadgeState, setOpenBadgeState] = useState(openBadgeInitialState);

  async function handleInputChanges(key, value) {
    await setOpenBadgeState({ ...openBadgeState, [key]: value });
  }

  const onCreateClick = () => {
    if (
      !openBadgeState.name ||
      !openBadgeState.description ||
      !openBadgeState.imageUrl ||
      !openBadgeState.criteria
    ) {
      toast.current.show();
    } else {
      addBadge();
    }
  };

  function onHomeClick() {
    navigate("/home");
  }

  function onCancelClick() {
    navigate("/home", { state: { view: "manageopenbadges" } });
  }

  async function addBadge() {
    try {
      const openBadge = { ...openBadgeState };

      const apiCall = await API.graphql(
        graphqlOperation(createOpenBadge, { input: openBadge })
      );
      const newOpenBadge = apiCall.data.createOpenBadge;

      navigate("/openBadgeDetails", { state: { openBadge: newOpenBadge } });
    } catch (err) {
      console.log("error creating Transaction:", err);
    }
  }

  //IMAGES
  const [images, setImages] = useState([]);
  const [searchVal, setSearchVal] = useState(); // search

  function getCredentialImages() {
    Storage.list("")
      .then((result) => setImages(result))
      .catch((err) => console.log(err));
  }

  const dialogRef = useRef(null);
  const onSelectImage = () => {
    getCredentialImages();
    dialogRef.current.show();
  };

  const handleImageSearch = (e) => {
    setSearchVal(e.detail.value);
  };

  const handleImageSearchReset = () => {
    setSearchVal(undefined);
  }; // predefined selection

  const handleImageConfirm = (e) => {
    openBadgeState.imageUrl = e.detail.selectedItems[0].innerText;
    setOpenBadgeState(openBadgeState);
  };

  const formCreateOpenBadge = (
    <>
      <Form style={{ margin: "32px" }}>
        <FormItem label={i18nBundle.getText("Name")}>
          <TextArea
            required="true"
            rows="2"
            growing="true"
            style={{ width: "450px" }}
            value={openBadgeState.name}
            onInput={(event) => handleInputChanges("name", event.target.value)}
          />
        </FormItem>
        <FormItem label={i18nBundle.getText("Description")}>
          <TextArea
            required="true"
            rows="3"
            growing="true"
            style={{ width: "450px" }}
            value={openBadgeState.description}
            onInput={(event) =>
              handleInputChanges("description", event.target.value)
            }
          />
        </FormItem>
        <FormItem label={i18nBundle.getText("Issuer")}>
          <Title level="H5">SAP</Title>
        </FormItem>
        <FormItem label={i18nBundle.getText("Image")}>
          <FlexBox
            alignItems="Center"
            direction="Row"
            displayInline
            fitContainer
            justifyContent="Start"
            wrap="Wrap"
          >
            <Image
              src={cdnUrlPrefix + openBadgeState.imageUrl}
              height="120px"
              width="120px"
              key={openBadgeState.imageUrl}
            ></Image>
            <Button
              icon="picture"
              style={{ margin: "8px" }}
              onClick={onSelectImage}
            >
              Browse
            </Button>
          </FlexBox>
        </FormItem>
        <FormItem label={i18nBundle.getText("Criteria")}>
          <TextArea
            required="true"
            rows="3"
            growing="false"
            style={{ width: "450px" }}
            value={openBadgeState.criteria}
            onInput={(event) =>
              handleInputChanges("criteria", event.target.value)
            }
          />
        </FormItem>
        <FormItem label={i18nBundle.getText("Is Organization Credential")}>
          <Switch
            design="Graphical"
            onChange={(event) =>
              openBadgeState.isOrgCredential = event.returnValue
            }
          />
        </FormItem>
        <FormItem label={i18nBundle.getText("Is Sharable")}>
          <Switch
            design="Graphical"
            onChange={(event) =>
              openBadgeState.isShareable = event.returnValue
            }
          />
        </FormItem>
      </Form>

      <Toast ref={toast}>
        {i18nBundle.getText("Please fill in all fields")}
      </Toast>
    </>
  );

  return (
    <>
      <Page
        footer={
          <Bar
            endContent={
              <>
                <Button icon="sys-cancel" onClick={onCancelClick}>
                  {i18nBundle.getText("Cancel")}
                </Button>
                <Button icon="create" onClick={onCreateClick}>
                  {i18nBundle.getText("Create")}
                </Button>
              </>
            }
          />
        }
        header={
          <Bar startContent={<Button icon="home" onClick={onHomeClick} />}>
            <Title>{i18nBundle.getText("Create OpenBadge")}</Title>
          </Bar>
        }
        style={{
          height: "800px",
        }}
      >
        {formCreateOpenBadge}
      </Page>

      <SelectDialog
        ref={dialogRef}
        headerText="Select Image"
        onSearchInput={handleImageSearch}
        onSearch={handleImageSearch}
        onSearchReset={handleImageSearchReset}
        onConfirm={handleImageConfirm}
      >
        {images.map((image) => (
          <CustomListItem key={image.key}>
            <FlexBox
              alignItems="Center"
              direction="Column"
              displayInline
              fitContainer
              justifyContent="Start"
              wrap="Wrap"
            >
              <Image
                src={cdnUrlPrefix + image.key}
                height="100px"
                width="100px"
              ></Image>
              <Label>{image.key}</Label>
            </FlexBox>
          </CustomListItem>
        ))}
      </SelectDialog>
    </>
  );
}

export default CreateOpenBadge;
