// @ts-nocheck

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//import { registerI18nLoader } from "@ui5/webcomponents-base/dist/asset-registries/i18n.js";
//import parse from '@ui5/webcomponents-base/dist/PropertiesFileFormat.js';

// const supportedLocales = ["en"];
// supportedLocales.forEach((localeToRegister) => {
//   registerI18nLoader("credentialsApp", localeToRegister, async (localeId) => {
//     const props = await (
//       await fetch(`./i18n/i18n_${localeId}.properties`)
//     ).text();
//     return parse(props); // this call is required for parsing the properties text
//   });
// });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
