/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOpenBadge = /* GraphQL */ `
  query GetOpenBadge($id: ID!) {
    getOpenBadge(id: $id) {
      id
      type
      version
      status
      isOrgCredential
      isShareable
      createdBy
      name
      description
      imageUrl
      criteria
      createdAt
      updatedAt
    }
  }
`;
export const listOpenBadges = /* GraphQL */ `
  query ListOpenBadges(
    $filter: ModelOpenBadgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpenBadges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        version
        status
        isOrgCredential
        isShareable
        createdBy
        name
        description
        imageUrl
        criteria
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCertification = /* GraphQL */ `
  query GetCertification($id: ID!) {
    getCertification(id: $id) {
      id
      type
      version
      status
      isOrgCredential
      isShareable
      createdBy
      name
      description
      validFrom
      validUntil
      createdAt
      updatedAt
    }
  }
`;
export const listCertifications = /* GraphQL */ `
  query ListCertifications(
    $filter: ModelCertificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        version
        status
        isOrgCredential
        isShareable
        createdBy
        name
        description
        validFrom
        validUntil
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOffer = /* GraphQL */ `
  query GetOffer($id: ID!) {
    getOffer(id: $id) {
      id
      user {
        email
        firstName
        lastName
        orgName
        role
        createdAt
        updatedAt
      }
      credentialType
      openBadge {
        id
        type
        version
        status
        isOrgCredential
        isShareable
        createdBy
        name
        description
        imageUrl
        criteria
        createdAt
        updatedAt
      }
      certification {
        id
        type
        version
        status
        isOrgCredential
        isShareable
        createdBy
        name
        description
        validFrom
        validUntil
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
      offerUserId
      offerOpenBadgeId
      offerCertificationId
    }
  }
`;
export const listOffers = /* GraphQL */ `
  query ListOffers(
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          email
          firstName
          lastName
          orgName
          role
          createdAt
          updatedAt
        }
        credentialType
        openBadge {
          id
          type
          version
          status
          isOrgCredential
          isShareable
          createdBy
          name
          description
          imageUrl
          criteria
          createdAt
          updatedAt
        }
        certification {
          id
          type
          version
          status
          isOrgCredential
          isShareable
          createdBy
          name
          description
          validFrom
          validUntil
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
        offerUserId
        offerOpenBadgeId
        offerCertificationId
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($email: String!) {
    getUser(email: $email) {
      email
      firstName
      lastName
      orgName
      role
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $email: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        firstName
        lastName
        orgName
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCheckedCredentials = /* GraphQL */ `
  query GetCheckedCredentials($email: String!) {
    getCheckedCredentials(email: $email) {
      email
      credentials
      createdAt
      updatedAt
    }
  }
`;
export const listCheckedCredentials = /* GraphQL */ `
  query ListCheckedCredentials(
    $email: String
    $filter: ModelCheckedCredentialsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCheckedCredentials(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        credentials
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUncheckedCredentials = /* GraphQL */ `
  query GetUncheckedCredentials($email: String!) {
    getUncheckedCredentials(email: $email) {
      email
      credentials
      createdAt
      updatedAt
    }
  }
`;
export const listUncheckedCredentials = /* GraphQL */ `
  query ListUncheckedCredentials(
    $email: String
    $filter: ModelUncheckedCredentialsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUncheckedCredentials(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        credentials
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
