// @ts-nocheck

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  List,
  CustomListItem,
  FlexBox,
  Title,
  Label,
  Button,
} from "@ui5/webcomponents-react";
import { Image } from "@aws-amplify/ui-react";
import "@ui5/webcomponents-icons/dist/AllIcons.js";

import { useI18nBundle } from "@ui5/webcomponents-react-base";

import Amplify, { API, graphqlOperation } from "aws-amplify";
import { listOpenBadges } from "./../graphql/queries";
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

function ManageOpenBadges({
  cdnUrlPrefix,
  userEmail
}) {
  let navigate = useNavigate();
  useEffect(() => {
    fetchOpenBadges();
  }, []);

  const [openBadges, setOpenBadges] = useState([]);

  async function fetchOpenBadges() {
    try {
      const openBadgeData = await API.graphql(graphqlOperation(listOpenBadges));
      const openBadges = openBadgeData.data.listOpenBadges.items;
      setOpenBadges(openBadges);
    } catch (err) {
      console.log("error fetching openBadges");
    }
  }

  const onBadgeDetailsClick = (openBadge) => {
    setOpenBadges([]);
    navigate("/openBadgeDetails", { state: { openBadge: openBadge } });
  };

  const onCreateOpenBadgeClick = () => {
    navigate("/createOpenBadge", { state: { userEmail: userEmail , cdnUrlPrefix : cdnUrlPrefix} });
  };

  return (
    <>
      <FlexBox
        alignItems="Start"
        direction="Row"
        justifyContent="End"
        wrap="Wrap"
        style={{ width: "100%" }}
      >
        <Button design="Emphasized" onClick={onCreateOpenBadgeClick}>
          Create OpenBadge
        </Button>
      </FlexBox>

      <List mode="SingleSelect" separators="All">
        {openBadges.map((openBadge) => (
          <CustomListItem>
            <FlexBox
              alignItems="Center"
              justifyContent="SpaceBetween"
              wrap="Wrap"
              style={{ width: "100%" }}
            >
              <FlexBox
                alignItems="Start"
                direction="Column"
                justifyContent="Start"
                wrap="Wrap"
                style={{ width: "70%" }}
              >
                <Title
                  wrappingType="Normal"
                  level="H5"
                  style={{ margin: "8px" }}
                >
                  {openBadge.name}
                </Title>

                <Label style={{ margin: "8px" }} wrappingType="Normal">
                  {openBadge.description}
                </Label>
              </FlexBox>

              <Image
                src={cdnUrlPrefix + openBadge.imageUrl}
                height="60px"
                width="60x"
              ></Image>
              <Button
                icon="detail-view"
                design="Transparent"
                onClick={(e) => {
                  e.markerAllowTableRowSelection = true;
                  onBadgeDetailsClick(openBadge);
                }}
              ></Button>
            </FlexBox>
          </CustomListItem>
        ))}
      </List>
    </>
  );
}

export default ManageOpenBadges;
