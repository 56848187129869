// @ts-nocheck

import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableColumn,
  Label,
  Dialog,
  Bar,
  Button,
  Modals,
  FlexBox,
  FileUploader,
  Badge,
  Avatar,
  FlexBoxAlignItems,
  TableRow,
  TableCell,
} from "@ui5/webcomponents-react";
import { useI18nBundle } from "@ui5/webcomponents-react-base";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";

import Amplify, { API, graphqlOperation } from "aws-amplify";
import { createUser, createOffer } from "../graphql/mutations";
import { getUser, listOffers } from "../graphql/queries";
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

function UploadOffers({
  uploadOffersState,
  setUploadOffersState,
  csvOffersDialogState,
  setCSVOffersDialogState,
  offers,
  setOffers,
  createOfferRows,
  offerRows,
  setOfferRows,
}) {
  const i18nBundle = useI18nBundle("myApp");
  const showToast = Modals.useShowToast();

  const [file, setFile] = useState();
  const [users, setUsers] = useState([]);
  let count = 0
  let newOffers =[];
  let newOffersRows = [];

  const fileReader = new FileReader();

  async function handleInputChanges(key, value) {
    await setCreateOfferState({ ...createOfferState, [key]: value });
  }

  const onIssueClick = () => {
    if (users.length === 0) {
      showToast({
        children: "Fill in all fields!",
      });
    } else {
      users.forEach(user => {
        addUserCredential(user);
      });
    }
  };

  const onCSVImport = (e) => {
    let file = e.target.files[0];
    setFile(file);
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        csvFileToArray(csvOutput);
      };

      fileReader.readAsText(file);
    }
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const users = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header.trim()] = values[index].trim();
        return object;
      }, {});
      return obj;
    });

    setUsers(users);
  };

  async function addUserCredential(user) {
    try {
      const apiCall = await API.graphql(
        graphqlOperation(getUser, { email: user.Email })
      );

      const userObj = {
        firstName: user.FirstName,
        lastName: user.LastName,
        orgName: user.Organization,
        email: user.Email.toLowerCase(),
        role: uploadOffersState.role,
      };

      if (!apiCall.data.getUser) {
        await API.graphql(graphqlOperation(createUser, { input: userObj }));
      }

      let userOffer = {
        offerUserId: userObj.email,
        status: "ISSUED",
        credentialType: uploadOffersState.credentialType,
      };

      if (uploadOffersState.credentialType === "OPENBADGE") {
        userOffer = {
          ...userOffer,
          offerOpenBadgeId: uploadOffersState.credentialId,
        };
      } else if (uploadOffersState.credentialType === "CERTIFICATION") {
        userOffer = {
          ...userOffer,
          offerCertificationId: uploadOffersState.credentialId,
        };
      }

      let filter = {
        offerUserId: {
          eq: userObj.email,
        }
      };

      if (uploadOffersState.credentialType === "OPENBADGE") {
        filter = {
          ...filter,
          offerOpenBadgeId: {
            eq: uploadOffersState.credentialId,
          },
        };
      } else if (uploadOffersState.credentialType === "CERTIFICATION") {
        filter = {
          ...filter,
          offerCertificationId: {
            eq: uploadOffersState.credentialId,
          },
        };
      }

      const offersData = await API.graphql({
        query: listOffers,
        variables: { filter: filter },
      });
      if (offersData.data.listOffers.items.length === 0) {
        const offerApiCall = await API.graphql(
          graphqlOperation(createOffer, { input: userOffer })
        );
        let newOffer = offerApiCall.data.createOffer;
        let newOfferRow = createOfferRows([newOffer]);
        newOffers.push(newOffer);
        newOffersRows.push(newOfferRow);
        count++;
        if(users.length === count){
          setOffers([...offers, ...newOffers]);
          setOfferRows([...offerRows, ...newOffersRows]);
          setCSVOffersDialogState(false);
        }
      } else {
        showToast({
          children: "User already offered Credential!",
        });
      }
    } catch (err) {
      console.log("error creating Transaction:", err);
    }
  }

  const csvUsersContent = (
    <>
      <FlexBox
        alignItems={FlexBoxAlignItems.Center}
        justifyContent="End"
        style={{ margin: "8px" }}
      >
        <FileUploader hideInput accept=".csv" onChange={onCSVImport}>
          <Avatar icon="upload" />
        </FileUploader>
        <FileUploader hideInput accept=".csv" onChange={onCSVImport}>
          <Badge>Select CSV File</Badge>
        </FileUploader>
      </FlexBox>
      <Table
        columns={
          <>
            <TableColumn>
              <Label>First Name</Label>
            </TableColumn>
            <TableColumn>
              <Label>Last Name</Label>
            </TableColumn>
            <TableColumn>
              <Label>Email</Label>
            </TableColumn>
            {uploadOffersState.isOrgCredential && (
              <TableColumn>
                <Label>Organization</Label>
              </TableColumn>
            )}
          </>
        }
      >
        {users.map((user) => (
          <TableRow>
            <TableCell>
              <Label>{user.FirstName}</Label>
            </TableCell>
            <TableCell>
              <Label>{user.LastName}</Label>
            </TableCell>
            <TableCell>
              <Label>{user.Email}</Label>
            </TableCell>
            {uploadOffersState.isOrgCredential && (
              <TableCell>
                <Label>{user.Organization}</Label>
              </TableCell>
            )}
          </TableRow>
        ))}
      </Table>
    </>
  );

  return (
    <Dialog
      headerText={i18nBundle.getText("User Offers - CSV Upload")}
      resizable="true"
      open={csvOffersDialogState}
      onAfterClose={() => {
        setCSVOffersDialogState(false);
      }}
      footer={
        <Bar
          endContent={
            <>
              <Button
                icon="sys-cancel"
                onClick={() => {
                  setCSVOffersDialogState(false);
                }}
              >
                {i18nBundle.getText("Cancel")}
              </Button>
              <Button icon="create" onClick={onIssueClick}>
                {i18nBundle.getText("Issue")}
              </Button>
            </>
          }
        />
      }
      style={{ width: "50%", height: "50%" }}
    >
      {csvUsersContent}
    </Dialog>
  );
}

export default UploadOffers;
