// @ts-nocheck

import {
  Form,
  FormItem,
  Input,
  Dialog,
  Bar,
  Button,
  Modals
} from "@ui5/webcomponents-react";
import { useI18nBundle } from "@ui5/webcomponents-react-base";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";

import Amplify, { API, graphqlOperation } from "aws-amplify";
import { createUser, createOffer } from "../graphql/mutations";
import { getUser, listOffers } from "../graphql/queries";
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

function CreateOffer({
  createOfferState,
  setCreateOfferState,
  offerDialogState,
  setOfferDialogState,
  offers,
  setOffers,
  createOfferRows,
  offerRows,
  setOfferRows
}) {
  const i18nBundle = useI18nBundle("myApp");
  const showToast = Modals.useShowToast();

  async function handleInputChanges(key, value) {
    await setCreateOfferState({ ...createOfferState, [key]: value });
  }

  const onIssueClick = () => {
    if (
      !createOfferState.firstName ||
      !createOfferState.lastName ||
      !createOfferState.email
    ) {
      showToast({
        children: "Fill in all fields!"
      });
    } else {
      addUserCredential();
    }
  };

  async function addUserCredential() {
    try {

      const apiCall = await API.graphql(
        graphqlOperation(getUser, { email: createOfferState.email })
      );

      const userObj = {
        firstName: createOfferState.firstName,
        lastName: createOfferState.lastName,
        orgName: createOfferState.orgName,
        email: createOfferState.email.toLowerCase(),
        role: createOfferState.role,
      };

      if (!apiCall.data.getUser) {
        await API.graphql(
          graphqlOperation(createUser, { input: userObj })
        );
      }

      let userOffer = {
        offerUserId: createOfferState.email,
        status: "ISSUED",
        credentialType: createOfferState.credentialType,
      };

      if(createOfferState.credentialType === "OPENBADGE") {
        userOffer = {
          ...userOffer,
          offerOpenBadgeId: createOfferState.credentialId,
        };

      } else if(createOfferState.credentialType === "CERTIFICATION") {
        userOffer = {
          ...userOffer,
          offerCertificationId: createOfferState.credentialId,
        };
      }

      let filter = {
        offerUserId: {
          eq: createOfferState.email,
        }
      };

      if(createOfferState.credentialType === "OPENBADGE") {
        filter = {
          ...filter,
          offerOpenBadgeId: {
            eq: createOfferState.credentialId,
          }
        };
      } else if(createOfferState.credentialType === "CERTIFICATION") {
        filter = {
          ...filter,
          offerCertificationId: {
            eq: createOfferState.credentialId,
          }
        };
      }

      const offersData = await API.graphql(
       { query: listOffers, variables: { filter: filter}})
      ;

      if(offersData.data.listOffers.items.length === 0) {
        const offerApiCall = await API.graphql(
          graphqlOperation(createOffer, { input: userOffer })
        );
        let newOffer = offerApiCall.data.createOffer
        let newOfferRow = createOfferRows([newOffer]);
        setOffers([...offers, newOffer]);
        setOfferRows([...offerRows, ...newOfferRow]);
      }else{
        showToast({
          children: "User already offered Credential!"
        });
      }
      
      setOfferDialogState(false);
    } catch (err) {
      console.log("error creating Transaction:", err);
    }
  }

  const formUserDetails = (
    <>
      <Form>
        <FormItem label={i18nBundle.getText("First Name")}>
          <Input
            required="false"
            value={createOfferState.firstName}
            onInput={(event) =>
              handleInputChanges("firstName", event.target.value)
            }
          />
        </FormItem>
        <FormItem label={i18nBundle.getText("Last Name")}>
          <Input
            required="false"
            value={createOfferState.lastName}
            onInput={(event) =>
              handleInputChanges("lastName", event.target.value)
            }
          />
        </FormItem>
        {createOfferState.isOrgCredential && (
          <FormItem
            label={i18nBundle.getText("Organization Name")}
            setState={createOfferState.isOrgCredential}
          >
            <Input
              required="true"
              value={createOfferState.orgName}
              onInput={(event) =>
                handleInputChanges("orgName", event.target.value)
              }
            />
          </FormItem>
        )}
        <FormItem label={i18nBundle.getText("Email")}>
          <Input
            required="true"
            value={createOfferState.email}
            onInput={(event) => handleInputChanges("email", event.target.value)}
          />
        </FormItem>
      </Form>
    </>
  );

  return (
    <Dialog
      headerText={i18nBundle.getText("User Offer")}
      resizable="true"
      open={offerDialogState}
      onAfterClose={() => {
        setOfferDialogState(false);
      }}
      footer={
        <Bar
          endContent={
            <>
              <Button
                icon="sys-cancel"
                onClick={() => {
                  setOfferDialogState(false);
                }}
              >
                {i18nBundle.getText("Cancel")}
              </Button>
              <Button icon="create" onClick={onIssueClick}>
                {i18nBundle.getText("Issue")}
              </Button>
            </>
          }
        />
      }
    >
      {formUserDetails}
    </Dialog>
  );
}

export default CreateOffer;
