// @ts-nocheck

import { useEffect, useState } from "react";
import {
  Avatar,
  ResponsiveGridLayout,
  FlexBox,
  FileUploader,
  Label,
  Badge,
  FlexBoxAlignItems
} from "@ui5/webcomponents-react";
import { Image } from "@aws-amplify/ui-react";
import "@ui5/webcomponents-icons/dist/AllIcons.js";

import { useI18nBundle } from "@ui5/webcomponents-react-base";

import Amplify, { Storage } from "aws-amplify";
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

function ManageImages() {
  useEffect(() => {
    getCredentialImages();
  }, []);

  let urlPrefix = "";
  const host = window.location.host;
  if (host.startsWith("localhost") || host.indexOf("decreds") > -1) {
    urlPrefix = "https://cdn.decreds.io/public/"; //local Dev + Testing
  } else {
    urlPrefix = "https://cdn.sap-credentials.io/public/"; //Prod
  }

  //IMAGES
  const [images, setImages] = useState([]);

  async function onImageUpload(e) {
    const file = e.target.files[0];
    try {
      await Storage.put(file.name, file, {
        contentType: file.type, // contentType is optional
      });
      getCredentialImages();
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  function getCredentialImages() {
    Storage.list("")
      .then((result) => setImages(result))
      .catch((err) => console.log(err));
  }

  return (
    <>
      <FlexBox justifyContent="End">
        <FlexBox alignItems={FlexBoxAlignItems.Center}>
          <FileUploader
            hideInput
            onChange={onImageUpload}
            accept=".png,.jpeg,.jpg"
          >
            <Avatar icon="upload" />
          </FileUploader>
          <FileUploader
            hideInput
            onChange={onImageUpload}
            accept=".png,.jpeg,.jpg"
          >
            <Badge>Upload Image</Badge>
          </FileUploader>
        </FlexBox>
      </FlexBox>

      <ResponsiveGridLayout style={{ margin: "16px" }}>
        {images.map((image) => (
          <div
            className="responsiveGridLayoutItem"
            style={{
              gridColumn: "span 3",
            }}
          >
            <FlexBox direction="Column" alignItems={FlexBoxAlignItems.Center}>
              <Image src={urlPrefix + image.key} height="16rem" width="16rem" />
              <Label>{image.key}</Label>
            </FlexBox>
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

export default ManageImages;
