// @ts-nocheck

import { useEffect, useState } from "react";
import {
  ObjectPage,
  ObjectPageSection,
  List,
  CustomListItem,
  FlexBox,
  Title,
  Label,
  ObjectStatus,
} from "@ui5/webcomponents-react";
import { Image } from "@aws-amplify/ui-react";
import "@ui5/webcomponents-icons/dist/AllIcons.js";

import { useI18nBundle } from "@ui5/webcomponents-react-base";

import Amplify, { API } from "aws-amplify";
import { listOffers } from "./../graphql/queries";
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

function MyCredentials({ userEmail, cdnUrlPrefix }) {
  useEffect(() => {
    fetchUserOffers();
  }, []);
  const [userOffers, setUserOffers] = useState([]);

  async function fetchUserOffers() {
    try {
      let filter = {
        offerUserId: {
          eq: userEmail,
        },
      };

      const offersData = await API.graphql({
        query: listOffers,
        variables: { filter: filter },
      });
      var userOffers = offersData.data.listOffers.items;
      setUserOffers(userOffers);
    } catch (err) {
      console.log("error fetching openBadges" + err);
    }
  }

  function getOpenBadgeLinkedInShareUrl(userOffer) {
    return (
      "https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=" +
      userOffer.openBadge.name +
      "&organizationName=SAP&organizationId=1115&issueYear=2022&issueMonth=10&certId=" +
      userOffer.id +
      "&certUrl=" +
      cdnUrlPrefix +
      userOffer.openBadge.imageUrl
    );
  }

  return (
    <>
      <ObjectPage selectedSectionId="badges">
        <ObjectPageSection
          id="badges"
          titleText="Badges"
          titleTextUppercase="false"
        >
          <List>
            {userOffers.map((userOffer) => (
              <>
                {userOffer.credentialType === "OPENBADGE" && (
                  <CustomListItem>
                    <FlexBox
                      alignItems="Center"
                      justifyContent="Start"
                      wrap="Wrap"
                      style={{ width: "100%" }}
                    >
                      <FlexBox
                        alignItems="Start"
                        direction="Column"
                        justifyContent="Start"
                        wrap="Wrap"
                        style={{ width: "60%" }}
                      >
                        <Title
                          wrappingType="Normal"
                          level="H5"
                          style={{ margin: "8px" }}
                        >
                          {userOffer.openBadge.name}
                        </Title>

                        <Label style={{ margin: "8px" }} wrappingType="Normal">
                          {" "}
                          {userOffer.openBadge.description}
                        </Label>
                      </FlexBox>

                      <Image
                        style={{ margin: "24px" }}
                        src={cdnUrlPrefix + userOffer.openBadge.imageUrl}
                        height="64px"
                        width="64px"
                      />

                      <ObjectStatus
                        state="Information"
                        style={{ width: "10%" }}
                      >
                        {userOffer.status}
                      </ObjectStatus>

                      {userOffer.openBadge.isShareable === true && (
                        <>
                          <div>
                            <a
                              href={getOpenBadgeLinkedInShareUrl(userOffer)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="https://download.linkedin.com/desktop/add2profile/buttons/en_US.png "
                                alt="LinkedIn Add to Profile button"
                              />
                            </a>
                          </div>
                        </>
                      )}
                    </FlexBox>
                  </CustomListItem>
                )}
              </>
            ))}
          </List>
        </ObjectPageSection>
{/*         <ObjectPageSection
          id="certifications"
          titleText="Certifications"
          titleTextUppercase="false"
        >
          <List>
            {userOffers.map((userOffer) => (
              <>
                {userOffer.credentialType === "CERTIFICATION" && (
                  <CustomListItem>
                    <FlexBox
                      alignItems="Center"
                      justifyContent="Start"
                      wrap="Wrap"
                      style={{ width: "100%" }}
                    >
                      <FlexBox
                        alignItems="Start"
                        direction="Column"
                        justifyContent="Start"
                        wrap="Wrap"
                        style={{ width: "70%" }}
                      >
                        <Title
                          wrappingType="Normal"
                          level="H5"
                          style={{ margin: "8px" }}
                        >
                          {userOffer.certification.name}
                        </Title>

                        <Label style={{ margin: "8px" }} wrappingType="Normal">
                          {" "}
                          {userOffer.certification.description}
                        </Label>
                      </FlexBox>

                      <ObjectStatus
                        state="Information"
                        style={{ width: "10%" }}
                      >
                        {userOffer.status}
                      </ObjectStatus>
                      {userOffer.certification.isShareable === true && (
                        <>
                          <div>
                            <a
                              href="https://www.linkedin.com/profile/add?name=Certification Name&organizationId=1115"
                              target="_blank"
                            >
                              <img
                                src="https://download.linkedin.com/desktop/add2profile/buttons/en_US.png "
                                alt="LinkedIn Add to Profile button"
                              />
                            </a>
                          </div>
                        </>
                      )}
                    </FlexBox>
                  </CustomListItem>
                )}
              </>
            ))}
          </List>
        </ObjectPageSection> */}
{/*         <ObjectPageSection
          id="courses"
          titleText="Courses"
          titleTextUppercase="false"
        >
          <List>
            <CustomListItem>
              <FlexBox
                alignItems="Center"
                justifyContent="SpaceBetween"
                wrap="Wrap"
                style={{ width: "100%" }}
              >
                <FlexBox
                  alignItems="Start"
                  direction="Column"
                  justifyContent="Start"
                  wrap="Wrap"
                  style={{ width: "70%" }}
                >
                  <Title
                    wrappingType="Normal"
                    level="H5"
                    style={{ margin: "8px" }}
                  >
                    SAP Product Standards
                  </Title>

                  <Label style={{ margin: "8px" }}>Apr 2016 to Nov 2016</Label>

                  <Title
                    level="H5"
                    style={{ margin: "8px" }}
                    wrappingType="Normal"
                  >
                    Bharath Medarametla
                  </Title>
                </FlexBox>

                <Image
                  src="https://blogs.sap.com/wp-content/uploads/2014/10/sap_logo_light_blue_573813.png"
                  height="50px"
                  width="50x"
                ></Image>
                <Image src="https://learn.microsoft.com/en-us/linkedin/media/share-button.png"></Image>
              </FlexBox>
            </CustomListItem>
          </List>
          <Title level="H5">
            {" "}
            *SAP & SFSF Learning Credentials - Integration Coming Soon!!
          </Title>
        </ObjectPageSection>
        <ObjectPageSection
          id="employment"
          titleText="Employment"
          titleTextUppercase="false"
        >
          <List>
            <CustomListItem>
              <FlexBox
                alignItems="Center"
                justifyContent="SpaceBetween"
                wrap="Wrap"
                style={{ width: "100%" }}
              >
                <FlexBox
                  alignItems="Start"
                  direction="Column"
                  justifyContent="Start"
                  wrap="Wrap"
                  style={{ width: "70%" }}
                >
                  <Title
                    wrappingType="Normal"
                    level="H5"
                    style={{ margin: "8px" }}
                  >
                    Lead Software Engineer
                  </Title>

                  <Label style={{ margin: "8px" }}>SAP, Palo Alto</Label>
                  <Label style={{ margin: "8px" }}>Start Date: Apr 2015</Label>
                  <Title
                    level="H5"
                    style={{ margin: "8px" }}
                    wrappingType="Normal"
                  >
                    Bharath Medarametla
                  </Title>
                </FlexBox>

                <Image
                  src="https://blogs.sap.com/wp-content/uploads/2014/10/sap_logo_light_blue_573813.png"
                  height="50px"
                  width="50x"
                ></Image>
                <Image src="https://learn.microsoft.com/en-us/linkedin/media/share-button.png"></Image>
              </FlexBox>
            </CustomListItem>
          </List>
          <Title level="H5">
            {" "}
            *SAP & SFSF EC Core Employee Credentials - Integration Coming Soon!!
          </Title>
        </ObjectPageSection> */}
      </ObjectPage>
    </>
  );
}

export default MyCredentials;
