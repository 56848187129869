/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOpenBadge = /* GraphQL */ `
  mutation CreateOpenBadge(
    $input: CreateOpenBadgeInput!
    $condition: ModelOpenBadgeConditionInput
  ) {
    createOpenBadge(input: $input, condition: $condition) {
      id
      type
      version
      status
      isOrgCredential
      isShareable
      createdBy
      name
      description
      imageUrl
      criteria
      createdAt
      updatedAt
    }
  }
`;
export const updateOpenBadge = /* GraphQL */ `
  mutation UpdateOpenBadge(
    $input: UpdateOpenBadgeInput!
    $condition: ModelOpenBadgeConditionInput
  ) {
    updateOpenBadge(input: $input, condition: $condition) {
      id
      type
      version
      status
      isOrgCredential
      isShareable
      createdBy
      name
      description
      imageUrl
      criteria
      createdAt
      updatedAt
    }
  }
`;
export const deleteOpenBadge = /* GraphQL */ `
  mutation DeleteOpenBadge(
    $input: DeleteOpenBadgeInput!
    $condition: ModelOpenBadgeConditionInput
  ) {
    deleteOpenBadge(input: $input, condition: $condition) {
      id
      type
      version
      status
      isOrgCredential
      isShareable
      createdBy
      name
      description
      imageUrl
      criteria
      createdAt
      updatedAt
    }
  }
`;
export const createCertification = /* GraphQL */ `
  mutation CreateCertification(
    $input: CreateCertificationInput!
    $condition: ModelCertificationConditionInput
  ) {
    createCertification(input: $input, condition: $condition) {
      id
      type
      version
      status
      isOrgCredential
      isShareable
      createdBy
      name
      description
      validFrom
      validUntil
      createdAt
      updatedAt
    }
  }
`;
export const updateCertification = /* GraphQL */ `
  mutation UpdateCertification(
    $input: UpdateCertificationInput!
    $condition: ModelCertificationConditionInput
  ) {
    updateCertification(input: $input, condition: $condition) {
      id
      type
      version
      status
      isOrgCredential
      isShareable
      createdBy
      name
      description
      validFrom
      validUntil
      createdAt
      updatedAt
    }
  }
`;
export const deleteCertification = /* GraphQL */ `
  mutation DeleteCertification(
    $input: DeleteCertificationInput!
    $condition: ModelCertificationConditionInput
  ) {
    deleteCertification(input: $input, condition: $condition) {
      id
      type
      version
      status
      isOrgCredential
      isShareable
      createdBy
      name
      description
      validFrom
      validUntil
      createdAt
      updatedAt
    }
  }
`;
export const createOffer = /* GraphQL */ `
  mutation CreateOffer(
    $input: CreateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    createOffer(input: $input, condition: $condition) {
      id
      user {
        email
        firstName
        lastName
        orgName
        role
        createdAt
        updatedAt
      }
      credentialType
      openBadge {
        id
        type
        version
        status
        isOrgCredential
        isShareable
        createdBy
        name
        description
        imageUrl
        criteria
        createdAt
        updatedAt
      }
      certification {
        id
        type
        version
        status
        isOrgCredential
        isShareable
        createdBy
        name
        description
        validFrom
        validUntil
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
      offerUserId
      offerOpenBadgeId
      offerCertificationId
    }
  }
`;
export const updateOffer = /* GraphQL */ `
  mutation UpdateOffer(
    $input: UpdateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    updateOffer(input: $input, condition: $condition) {
      id
      user {
        email
        firstName
        lastName
        orgName
        role
        createdAt
        updatedAt
      }
      credentialType
      openBadge {
        id
        type
        version
        status
        isOrgCredential
        isShareable
        createdBy
        name
        description
        imageUrl
        criteria
        createdAt
        updatedAt
      }
      certification {
        id
        type
        version
        status
        isOrgCredential
        isShareable
        createdBy
        name
        description
        validFrom
        validUntil
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
      offerUserId
      offerOpenBadgeId
      offerCertificationId
    }
  }
`;
export const deleteOffer = /* GraphQL */ `
  mutation DeleteOffer(
    $input: DeleteOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    deleteOffer(input: $input, condition: $condition) {
      id
      user {
        email
        firstName
        lastName
        orgName
        role
        createdAt
        updatedAt
      }
      credentialType
      openBadge {
        id
        type
        version
        status
        isOrgCredential
        isShareable
        createdBy
        name
        description
        imageUrl
        criteria
        createdAt
        updatedAt
      }
      certification {
        id
        type
        version
        status
        isOrgCredential
        isShareable
        createdBy
        name
        description
        validFrom
        validUntil
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
      offerUserId
      offerOpenBadgeId
      offerCertificationId
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      orgName
      role
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      orgName
      role
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      orgName
      role
      createdAt
      updatedAt
    }
  }
`;
export const createCheckedCredentials = /* GraphQL */ `
  mutation CreateCheckedCredentials(
    $input: CreateCheckedCredentialsInput!
    $condition: ModelCheckedCredentialsConditionInput
  ) {
    createCheckedCredentials(input: $input, condition: $condition) {
      email
      credentials
      createdAt
      updatedAt
    }
  }
`;
export const updateCheckedCredentials = /* GraphQL */ `
  mutation UpdateCheckedCredentials(
    $input: UpdateCheckedCredentialsInput!
    $condition: ModelCheckedCredentialsConditionInput
  ) {
    updateCheckedCredentials(input: $input, condition: $condition) {
      email
      credentials
      createdAt
      updatedAt
    }
  }
`;
export const deleteCheckedCredentials = /* GraphQL */ `
  mutation DeleteCheckedCredentials(
    $input: DeleteCheckedCredentialsInput!
    $condition: ModelCheckedCredentialsConditionInput
  ) {
    deleteCheckedCredentials(input: $input, condition: $condition) {
      email
      credentials
      createdAt
      updatedAt
    }
  }
`;
export const createUncheckedCredentials = /* GraphQL */ `
  mutation CreateUncheckedCredentials(
    $input: CreateUncheckedCredentialsInput!
    $condition: ModelUncheckedCredentialsConditionInput
  ) {
    createUncheckedCredentials(input: $input, condition: $condition) {
      email
      credentials
      createdAt
      updatedAt
    }
  }
`;
export const updateUncheckedCredentials = /* GraphQL */ `
  mutation UpdateUncheckedCredentials(
    $input: UpdateUncheckedCredentialsInput!
    $condition: ModelUncheckedCredentialsConditionInput
  ) {
    updateUncheckedCredentials(input: $input, condition: $condition) {
      email
      credentials
      createdAt
      updatedAt
    }
  }
`;
export const deleteUncheckedCredentials = /* GraphQL */ `
  mutation DeleteUncheckedCredentials(
    $input: DeleteUncheckedCredentialsInput!
    $condition: ModelUncheckedCredentialsConditionInput
  ) {
    deleteUncheckedCredentials(input: $input, condition: $condition) {
      email
      credentials
      createdAt
      updatedAt
    }
  }
`;
