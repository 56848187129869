// @ts-nocheck

import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Avatar, ShellBar } from '@ui5/webcomponents-react';
import { ThemeProvider } from "@ui5/webcomponents-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "@ui5/webcomponents-icons/dist/AllIcons.js";
//import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";

import Main from "./components/Main";

import { Authenticator, View, Image } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

import Home from "./components/Home";
import CreateOpenBadge from "./components/CreateOpenBadge";
import OpenBadgeDetails from "./components/OpenBadgeDetails";
import CreateCertification from "./components/CreateCertification";
import CertificationDetails from "./components/CertificationDetails";

import "@aws-amplify/ui-react/styles.css";

Amplify.configure(awsExports);

const components = {
  Header() {

    return (
      <View textAlign="center" padding={"20px"}>
        <Image
          alt="SAP Verifiable Credentials"
          src="https://logos.sap-credentials.io/credentials_logo.png"
        />
      </View>
    );
  }

};

function App() {
  //setTheme("sap_fiori_3");
  return (
    <Authenticator hideSignUp={true} components={components}>
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route path="home" element={<Home />} />
            <Route path="createOpenBadge" element={<CreateOpenBadge />} />
            <Route path="openBadgeDetails" element={<OpenBadgeDetails />} />
            <Route path="createCertification" element={<CreateCertification />} />
            <Route path="certificationDetails" element={<CertificationDetails />} />
          </Route>
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Page not found!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
    </Authenticator>
  );
}

export default App;
