// @ts-nocheck

import { useEffect, useState, useRef } from "react";

import {
  Title,
  Form,
  FormItem,
  Text,
  Table,
  TableColumn,
  TableCell,
  Label,
  TableRow,
  FlexBox,
  Button,
  DynamicPageTitle,
  DynamicPageHeader,
  ObjectPage,
  Breadcrumbs,
  BreadcrumbsItem,
  ObjectStatus,
  ObjectPageSection,
} from "@ui5/webcomponents-react";
import { useI18nBundle } from "@ui5/webcomponents-react-base";
import { PieChart } from "@ui5/webcomponents-react-charts";
import { useNavigate, useLocation } from "react-router-dom";
import CreateOffer from "./CreateOffer";
import UploadOffers from "./UploadOffers";
import Amplify, { API } from "aws-amplify";
import { listOffers } from "./../graphql/queries";
import awsExports from "../aws-exports";
Amplify.configure(awsExports);

function CertificationDetails() {
  let navigate = useNavigate();
  const i18nBundle = useI18nBundle("credentialsApp");

  const { state } = useLocation();
  const { certification } = state;

  const createOfferInitialState = {
    firstName: "",
    lastName: "",
    email: "",
    orgName: "",
    role: "USER",
    credentialId: certification.id,
    credentialType: "CERTIFICATION",
    isOrgCredential: certification.isOrgCredential,
  };
  const [createOfferState, setCreateOfferState] = useState(
    createOfferInitialState
  );
  const [offerDialogState, setOfferDialogState] = useState();
  const [offers, setOffers] = useState([]);
  const [offerRows, setOfferRows] = useState([]);

  const uploadOffersInitialState = {
    role: "USER",
    credentialId: certification.id,
    credentialType: "CERTIFICATION",
    isOrgCredential: certification.isOrgCredential,
  };
  const [uploadOffersState, setUploadOffersState] = useState(
    uploadOffersInitialState
  );

  const [csvOffersDialogState, setCSVOffersDialogState] = useState();

  useEffect(() => {
    fetchOffers(certification.id);
  }, []);

  async function fetchOffers(credentialId) {
    try {
      let filter = {
        offerCertificationId: {
          eq: credentialId,
        },
      };

      const offersData = await API.graphql({
        query: listOffers,
        variables: { filter: filter, limit: 10000 }
      });

      let offers = offersData.data.listOffers.items;
      setOffers(offers);
      setOfferRows(createOfferRows(offers));
    } catch (err) {
      console.log("error fetching certifications" + err);
    }
  }

  function onOfferAddClick() {
    setCreateOfferState(createOfferInitialState);
    setOfferDialogState(true);
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function getTotalCount(offers) {
    return offers.length;
  }

  function getPendingCount(offers) {
    let total = offers.length;
    let accepted = getAcceptedCount(offers);
    let revoked = getRevokedCount(offers);
    return total - accepted - revoked;
  }

  function getAcceptedCount(offers) {
    let count = 0;
    offers.forEach((offer) => {
      if (offer.status === "ACCEPTED") {
        count++;
      }
    });
    return count;
  }

  function getRevokedCount(offers) {
    let count = 0;
    offers.forEach((offer) => {
      if (offer.status === "REVOKED") {
        count++;
      }
    });
    return count;
  }

  function onBackClick() {
    navigate("/home", { state: { view: "managecertifications" } });
  }

  function onUploadCSVClick() {
    setUploadOffersState(uploadOffersInitialState);
    setCSVOffersDialogState(true);
  }

  const createOfferRows = (offers) => {
    return offers.map((offer) => (
      <TableRow>
        <TableCell>
          <Label>{offer.user.firstName}</Label>
        </TableCell>
        <TableCell>
          <Label>{offer.user.lastName}</Label>
        </TableCell>
        <TableCell>
          <Label>{offer.user.email}</Label>
        </TableCell>
        {certification.isOrgCredential && (
          <TableCell>
            <Label>{offer.user.orgName}</Label>
          </TableCell>
        )}
        <TableCell>
          <Label>{offer.status}</Label>
        </TableCell>
        <TableCell>
          <Label>{formatDate(offer.createdAt)}</Label>
        </TableCell>
        <TableCell>
          <Label>{formatDate(offer.updatedAt)}</Label>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <ObjectPage
        headerContent={
          <DynamicPageHeader>
            <FlexBox direction="Row" justifyContent="SpaceBetween">
              <FlexBox direction="Column" style={{ width: "50%" }}>
                <Text style={{ padding: "10px" }}>Certification 1.1</Text>
                <Text style={{ padding: "10px" }}>
                  {certification.description}
                </Text>
                <Form style={{ padding: "10px" }}>
                  <FormItem label={i18nBundle.getText("Valid From")}>
                    <Text>{certification.validFrom}</Text>
                  </FormItem>
                  <FormItem label={i18nBundle.getText("Valid Until")}>
                    <Text>{certification.validUntil}</Text>
                  </FormItem>
                  <FormItem label={i18nBundle.getText("Issuer")}>
                    <Title level="H5">SAP</Title>
                  </FormItem>
                  <FormItem label={i18nBundle.getText("ID")}>
                    <Text>{certification.id}</Text>
                  </FormItem>
                  <FormItem
                    label={i18nBundle.getText("Is Organization Credential")}
                  >
                    <Label wrappingType="Normal">
                      {certification.isOrgCredential.toString()}
                    </Label>
                  </FormItem>
                  <FormItem label={i18nBundle.getText("Is Shareable")}>
                    <Label wrappingType="Normal">
                      {certification.isShareable.toString()}
                    </Label>
                  </FormItem>
                  <FormItem label={i18nBundle.getText("Created By")}>
                    <Label wrappingType="Normal">
                      {certification.createdBy}
                    </Label>
                  </FormItem>
                </Form>
              </FlexBox>
              <FlexBox
                direction="Column"
                justifyContent="SpaceAround"
                style={{
                  width: "50%",
                }}
              >
                <PieChart
                  dataset={[
                    {
                      name: "Offers Pending",
                      count: getPendingCount(offers),
                    },
                    {
                      name: "Revoked",
                      count: getRevokedCount(offers),
                    },
                    {
                      name: "Accepted",
                      count: getAcceptedCount(offers),
                    },
                  ]}
                  dimension={{
                    accessor: "name",
                  }}
                  measure={{
                    accessor: "count",
                  }}
                  style={{
                    width: "100%",
                  }}
                />
                <Title
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Total Offers : {getTotalCount(offers)}
                </Title>
              </FlexBox>
            </FlexBox>
          </DynamicPageHeader>
        }
        headerTitle={
          <DynamicPageTitle
            breadcrumbs={
              <Breadcrumbs onItemClick={onBackClick}>
                <BreadcrumbsItem>Home</BreadcrumbsItem>
                <BreadcrumbsItem>Credential Details</BreadcrumbsItem>
              </Breadcrumbs>
            }
            header={certification.name}
            showSubHeaderRight="false"
            subHeader
          >
            <ObjectStatus state="Success">Active</ObjectStatus>
          </DynamicPageTitle>
        }
        showHideHeaderButton
        style={{
          height: "100%",
        }}
      >
        <ObjectPageSection titleText="User Offers">
          <FlexBox
            style={{
              width: "100%",
            }}
            direction="Row"
            justifyContent="End"
          >
            <Button
              icon="upload"
              onClick={onUploadCSVClick}
              style={{
                margin: "8px",
              }}
            >
              Upload CSV
            </Button>
            <Button
              icon="add"
              onClick={onOfferAddClick}
              style={{
                margin: "8px",
              }}
            >
              Add
            </Button>
          </FlexBox>

          <CreateOffer
            createOfferState={createOfferState}
            setCreateOfferState={setCreateOfferState}
            offerDialogState={offerDialogState}
            setOfferDialogState={setOfferDialogState}
            offers={offers}
            setOffers={setOffers}
            createOfferRows={createOfferRows}
            offerRows={offerRows}
            setOfferRows={setOfferRows}
          />

          <UploadOffers
            uploadOffersState={uploadOffersState}
            setUploadOffersState={setUploadOffersState}
            csvOffersDialogState={csvOffersDialogState}
            setCSVOffersDialogState={setCSVOffersDialogState}
            offers={offers}
            setOffers={setOffers}
            createOfferRows={createOfferRows}
            offerRows={offerRows}
            setOfferRows={setOfferRows}
          />

          <Table
            columns={
              <>
                <TableColumn>
                  <Label>First Name</Label>
                </TableColumn>
                <TableColumn>
                  <Label>Last Name</Label>
                </TableColumn>
                <TableColumn>
                  <Label>Email</Label>
                </TableColumn>
                {certification.isOrgCredential && (
                  <TableColumn>
                    <Label>Organization</Label>
                  </TableColumn>
                )}
                <TableColumn>
                  <Label>Status</Label>
                </TableColumn>
                <TableColumn>
                  <Label>Created</Label>
                </TableColumn>
                <TableColumn>
                  <Label>Updated</Label>
                </TableColumn>
              </>
            }
          >
            {offerRows}
          </Table>
        </ObjectPageSection>
      </ObjectPage>
    </>
  );
}

export default CertificationDetails;
