// @ts-nocheck

import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TabContainer, Tab } from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/AllIcons.js";

import { useI18nBundle } from "@ui5/webcomponents-react-base";
import { Amplify } from "aws-amplify";
import MyCredentials from "./MyCredentials";
import ManageOpenBadges from "./ManageOpenBadges";
import ManageCertifications from "./ManageCertifications";
import ManageImages from "./ManageImages";
import CheckedCredentials from "./CheckedCredentials";
import UncheckedCredentials from "./UncheckedCredentials";
import Wallets from "./Wallets";
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

function Home({}) {
  let navigate = useNavigate();
  const i18nBundle = useI18nBundle("credentialsApp");
  var isManageOpenBadgesSelected = false;
  var isManageCertificationsSelected = false;
  var isMyCredentailsSelected = false;
  var isWalletsSelected = false;
  const { state } = useLocation();
  //const { userEmail } = state;
  const userEmail = "bharath.medarametla@sap.com"; 

  if (state) {
    if (state.view === "manageopenbadges") {
      isManageOpenBadgesSelected = true;
      isManageCertificationsSelected = false;
      isMyCredentailsSelected = false;
      isWalletsSelected = false;
    } else if (state.view === "managecertifications") {
      isManageOpenBadgesSelected = false;
      isManageCertificationsSelected = true;
      isMyCredentailsSelected = false;
      isWalletsSelected = false;
    } else {
      isManageOpenBadgesSelected = false;
      isManageCertificationsSelected = false;
      isMyCredentailsSelected = true;
      isWalletsSelected = false;
    }
  }

  useEffect(() => {}, []);

  let cdnUrlPrefix = "";
  const host = window.location.host;
  if (host.startsWith("localhost") || host.indexOf("decreds") > -1) {
    cdnUrlPrefix = "https://cdn.decreds.io/public/"; //local Dev + Testing
  } else {
    cdnUrlPrefix = "https://cdn.sap-credentials.io/public/"; //Prod
  }

  const onTabSelect = () => {};

  return (
    <>
      <TabContainer onTabSelect={onTabSelect}>
        <Tab
          icon="employee"
          selected={isMyCredentailsSelected}
          text="My Credentials"
        >
          <MyCredentials 
            userEmail={userEmail}
            cdnUrlPrefix={cdnUrlPrefix}
          />
        </Tab>
        <Tab
          icon="badge"
          text="Manage OpenBadges"
          selected={isManageOpenBadgesSelected}
        >
          <ManageOpenBadges 
            cdnUrlPrefix={cdnUrlPrefix}
            userEmail={userEmail}
          />
        </Tab>
{/*         <Tab
          icon="documents"
          text="Manage Certifications"
          selected={isManageCertificationsSelected}
        >
          <ManageCertifications 
            userEmail={userEmail}
          />
        </Tab> */}
{/*         <Tab icon="check-availability" text="Verify Credentials">
          <TabContainer>
            <Tab text="Checked Credentials">
              <CheckedCredentials />
            </Tab>
            <Tab text="Unchecked Credentials">
              <UncheckedCredentials />
            </Tab>
          </TabContainer>
        </Tab> */}
        <Tab icon="picture" text="Manage Images">
          <ManageImages />
        </Tab>
        <Tab icon="wallet" text="Wallets" selected={isWalletsSelected}>
          <Wallets />
        </Tab>
      </TabContainer>
    </>
  );
}

export default Home;
